import Hero from "./components/Hero";
import ExploreProjects from "./components/ExploreProjects";
import Footer from "./components/Footer";
import DeliveryType from "./components/DeliveryType";
import { useEffect, useState } from "react";
import cookies from "js-cookie";

function App() {
  const currentLanguageCode = cookies.get('i18next');
  const [isPortrait, setIsPortrait] = useState(window.innerHeight > window.innerWidth);

  useEffect(() => {
    const handleOrientationChange = () => {
      setIsPortrait(window.innerHeight > window.innerWidth);
    };

    window.addEventListener('resize', handleOrientationChange);

    return () => {
      window.removeEventListener('resize', handleOrientationChange);
    };
  }, []);


  return (
    <>

      {!isPortrait && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 text-white">
          {currentLanguageCode === 'en' ? 'Please rotate your device to portrait mode for the best experience.' : 'يرجى استخدام الجوال على الوضع الرأسي للحصول على أفضل تجربة.'}
        </div>
      )}
      {isPortrait && (
        <div>
          <Hero />
          <ExploreProjects />
          <DeliveryType />
          <Footer />
        </div>
      )}

    </>
  );
}
export default App;