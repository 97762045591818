import React from 'react'
import i18next from "i18next";
// import slide01 from '../images/hero_Slide_01.png'
import slide02 from '../images/logo.png'
import down_arrow from '../images/down_arrow.svg'
import { useTranslation } from 'react-i18next';
// import FullScreenVideo from './FullScreenVideo';
import cookies from "js-cookie";

const Hero = () => {
    const { t } = useTranslation();
    const currentLanguageCode = cookies.get('i18next')

    return (
        <>
            {/* Mobile */}

            <div className='relative'>
                <div className='flex justify-start left-2 top-2 absolute'>
                    {currentLanguageCode === 'en' ?
                        <button className="flex justify-between border-[1px] w-full py-1 px-2 rounded-lg my-1" onClick={() => i18next.changeLanguage('ar')}>
                            <div className="flex justify-center mx-2">
                                <h1>AR</h1>
                            </div>
                        </button>
                        :
                        <button className="flex justify-between border-[1px] w-full py-1 px-2 rounded-lg my-1" onClick={() => i18next.changeLanguage('en')}>
                            <div className="flex justify-center mx-2">
                                <h1>EN</h1>
                            </div>
                        </button>
                    }
                </div>
            </div>

            <div className='md:hidden'>
                <div>
                    <img src={slide02} alt='slide01' />
                    {/* <div className='flex justify-start ms-10 items-center gap-5'>
                        <FullScreenVideo />
                        <h1 className='rounded-[20px] bg-opacity-80 font-bold text-xl text-black  w-fit text-center'>{t("Shahad_Story_Title")}</h1>
                    </div> */}
                </div>
                <div className='flex justify-center mt-10'>
                    <img className='animate-upDown' src={down_arrow} alt='down_arrow' />
                </div>

            </div>

            <div className='md:hidden '>
                <div className="video-container">
                    {/* <video width="100%" controls>
                        <source src="\videos\preFinalVideo.mp4" type="video/mp4" />

                    </video> */}
                </div>

            </div>

            {/* Desktop */}
            <div className='hidden md:block'>Desktop</div>
        </>
    )
}

export default Hero