import React from 'react'
import cookies from "js-cookie";
import fast_delivery_icon_ar from '../images/fast_delivery_icon_ar.png'
import fast_delivery_icon_en from '../images/fast_delivery_icon_en.png'
import { useTranslation } from 'react-i18next';


const DeliveryType = () => {

    const { t, ready } = useTranslation();
    const currentLanguageCode = cookies.get('i18next');
    return (
        <>
            {/* Mobile */}
            <div className='md:hidden '>
                <div className='flex flex-col text-[16px] items-center justify-center'>
                    <h1 className='text-[24px] font-bold'>{t('Order_and_Delivery')}</h1>
                    <h1 className='text-[#D8195B] text-[18px] font-bold mt-6'>{t('Regular_Order')}</h1>
                    <h1 className='text-[#7E7D7D] font-bold my-2'>{t('Delivery_Title_Note')}
                        <span className='font-normal'>{t('Delivery_Body_Note')}</span></h1>
                    <h1>{t('Delivery_Cost')}
                        <span className='font-bold'>{t('Delivery_Price')}</span></h1>

                    <div className='flex justify-between mt-[30px] overflow-hidden items-start w-full'>
                        <div className='flex flex-col ms-3'>
                            <h1 className='text-[#D8195B] text-[18px] font-bold'>{t('Fast_Order')}</h1>
                            <h1 className='text-[#7E7D7D] my-2'>{t('Fast_Delivery_Title_Note')}</h1>
                            <h1> {t('add')} <span className='font-bold'>{t('5riyals')}</span>{t('only')}</h1>
                        </div>
                        {currentLanguageCode === 'en' ?
                            <img className='-me-10 h-[100.78px] w-[200px]' src={fast_delivery_icon_en} alt='fast_delivery_icon_en' />
                            :
                            <img className='-me-10 h-[100.78px] w-[200px]' src={fast_delivery_icon_ar} alt='fast_delivery_icon_ar' />
                        }

                    </div>
                </div>
            </div>
            {/* Desktop */}
            <div className='hidden md:block'>Desktop</div>
        </>
    )
}
export default DeliveryType